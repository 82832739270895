import ClientLayout from "@/layouts/client-layout/ClientLayout.vue";
import plateforms from "./views/TradePlateforms.vue";
import webtrader from "./views/ToolsWebtrader.vue";
import webtrader5 from "./views/ToolsWebtrader5.vue";

export default (router) => {
  router.addRoute({
    path: "/plateforms",
    redirect: "/plateforms",
    component: ClientLayout,
    name: "plateforms",
    children: [
      {
        path: "/plateforms",
        name: "plateforms",
        component: plateforms,
        meta: {
          pageTitle: "title.platforms",
          breadcrumbs: ["title.platforms"],
          permissions: ["any"],
        },
      },
      {
        path: "/webTrader/:accountNumber?/:serviceId?",
        name: "webtrader",
        component: webtrader,
        meta: {
          pageTitle: "title.webTrader",
          breadcrumbs: ["title.webTrader"],
          permissions: ["any"],
        },
      },
      {
        path: "/webTrader5/:accountNumber?",
        name: "webtrader5",
        component: webtrader5,
        meta: {
          pageTitle: "title.webTrader",
          breadcrumbs: ["title.webTrader"],
          permissions: ["any"],
        },
      },
    ],
  });
};
